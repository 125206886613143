import create from "zustand";
import { getMarkers } from "./queries/getMarkers";
import { isEmpty, filter, includes, concat, get as _get, map } from "lodash/fp";

const useMapStore = create((set, get) => ({
    markers: [],
    searchIndex: {},
    searchQuery: "",
    searchResults: [],
    searchFilters: {
      branchOffice: false,
      ath: false,
      autoBank: false,
      subAgents: false,
    },
    map: {
      center: { lat: 18.471458, lng: -69.931225 },
      zoom: 9,
      bounds: {},
      CurrentLocation: false,
      CurrentLocationMarker: {},
    },
    handleSearchQuery: (newValue) =>
      set((state) => (state.searchQuery = newValue)),
    updateFilters: (key) =>
      set((state) => (state.searchFilters[key] = !state.searchFilters[key])),
    resetFilters: () =>
      set((state) =>
        Object.keys(state.searchFilters).map(
          (filter) => (state.searchFilters[filter] = false)
        )
      ),
    updateZoom: (newZoom) => set((state) => (state.map.zoom = newZoom)),
    updateCenter: (newCenter) => set((state) => (state.map.center = newCenter)),
    updateBounds: (newBounds) => set((state) => (state.map.bounds = newBounds)),
    updateCurrentLocation: (location) =>
      set((state) => (state.map.CurrentLocation = location)),
    updateCurrentLocationMarker: (locationMarker) =>
      set((state) => (state.map.CurrentLocationMarker = locationMarker)),
    loadMarkers: async () => {
      const data = await getMarkers();
      //console.log('DATA->',data)
      //console.log('DATA Concesionario->', _get("page.concesionarios.edges")(data) )
      let concesionarios = _get("page.concesionarios.edges")(data);
      let concesionariosFiltrados = map( (concesionario,i)=>{
        //console.log('ENTRA->',concesionario)
        if ( !concesionario.node.hasOwnProperty("id") ) concesionario.node.id = "Marker_"+concesionario.node.direccion
        if ( !concesionario.node.hasOwnProperty("type") ) concesionario.node.type = ["branchOffice"]
        return concesionario.node;
      })(concesionarios)

      //console.log('AFUERA->',concesionariosFiltrados)

      
      set({ markers: concesionariosFiltrados, searchResults: concesionariosFiltrados });
    },
    // loadIndex: () => set( (state) => (
    //     state.searchIndex = lunr(function() {
    //         this.addField("title");
    //         this.addField("address");
    //         this.setRef("id");
  
    //         state.markers.forEach((marker) => {
    //             this.addDoc(marker);
    //         }, this);
    //     })
    // ) ),
    searchMarkers: async (searchTerm) => {
      const lunr = (await import("elasticlunr")).default;
      let searchIndex = await lunr(function () {
        this.addField("title");
        this.addField("address");
        this.setRef("id");
        get().markers.forEach((marker) => {
          this.addDoc(marker);
        }, this);
      });
      let results = searchIndex.search(`${searchTerm}`);
      set((state) => {
        state.searchResults = [];
        if (!isEmpty(results)) {
          console.log("Resultados Lunr 1", results);
          results.map((marker) =>
            state.searchResults.push(filter({ id: marker.ref })(state.markers)[0])
          );
        } else {
          console.log("Resultados Lunr 2", results);
          results = searchIndex.search(`${searchTerm} ~1`);
          results.map((marker) =>
            state.searchResults.push(filter({ id: marker.ref })(state.markers)[0])
          );
        }
      });
    },
    filterMarkers: () =>
      set((state) => {
        state.searchResults = [];
        let filtersKey = Object.keys(state.searchFilters);
        let types = [];
        filtersKey.map((filter) =>
          state.searchFilters[filter] != false ? types.push(filter) : ""
        );
        state.searchResults = [];
        state.markers.map((marker) => {
          types.map((type) => {
            if (includes(type)(marker.type)) {
              state.searchResults.push(marker);
            }
          });
        });
      }),
  }));
  
  export {useMapStore};
  