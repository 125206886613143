/* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import shadowncard from "../../assets/img/default.png"
import InputMask from "react-input-mask"
import {get} from 'lodash/fp'
const config = require('../../../config/app')


class Cotizar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requesId: false,
      alert: "",
      alertType: "uk-hidden",
      error: false,
      btnText: "ENVIAR",
      datautos: [],
      feedBackInput: {
        apellido: "",
        cedula: "",
        celular: "",
        email: "",
        modelo: "",
        name: "",
        version_modelo: "",
      },

      urlOrginForm: config.url_flexible_form,
      featuredImage: shadowncard,
      title: "",
      sending: "",
      currentPrice: "",
      currentVercion: "",
      versiones: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
    }

    this.vehiculoChange = this.vehiculoChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.SendEntries = this.SendEntries.bind(this)
    this.validateInput = this.validateInput.bind(this)
    this.inputChange = this.inputChange.bind(this)
    //this.onChange       = this.onChange.bind(this);
  }
  inputChange(e) {
    //console.log(e)
  }
  componentDidMount() {
    let uri = new URL(window.location)
    let slug = uri.search.replace('?','').split("&")


    let slugFiltered = {}
    
    slug.map(slugFilter => { 
      let slugSplitted = slugFilter.split("=")
      slugFiltered[slugSplitted[0]]= slugSplitted[1]
    })


    const autos = this.state.datautos
    const dataAuto = autos.filter(row => row.node.slug === slugFiltered.id )

 
    
    if (dataAuto.length === 1) {
      this.setState({
        requesId: dataAuto[0].node.title,
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: slugFiltered.version,
        versiones: dataAuto[0].node.versiones.edges,
      })

      // this.vehiculoChange('vitara');
      
      document.querySelector('select[name="modelo"]').value =
        dataAuto[0].node.slug
      
        document.querySelector('select[name="version_modelo"]').value =
        slugFiltered.version


        
    }

    

  }

  vehiculoChange(e) {
    if (e !== "") {
      const dataAuto = this.state.datautos.filter(row => row.node.slug === e)
      

      this.setState({
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
    } else {
      this.setState({
        featuredImage: shadowncard,
        title: "",
        currentPrice: "",
        currentVercion: "",
        versiones: [
          {
            node: {
              precio_verion: "",
              version_modelo: "Versión del vehículo",
            },
          },
        ],
      })
    }
  }

  versionChange(e) {
    
    let { value } = e.target
    const data = this.state.versiones.filter(
      row => row.node.version_modelo === value
    )

    this.setState({
      currentPrice: data[0].node.precio_version,
      currentVercion: data[0].node.version_modelo,
    })
  }

  SendEntries() {
    if (typeof window !== "undefined") {
      // document.querySelector('#cotizarForm input[type=button]').setAttribute("disabled","disabled");
      const cotizarForm = Array.from(document.forms["cotizarForm"].elements)
      this.setState({btnText:"ENVIANDO..."})
      let data = { flexformdata: {} }

      cotizarForm.forEach(n =>
        n.type == "text" || n.type == "select-one" || n.type == "email"
          ? (data["flexformdata"][n.name] = n.value)
          : ""
      )

      this.validateInput(data["flexformdata"])
    }
  }

  validateInput(v) {
    let apellido, cedula, celular, email, modelo, name, version_modelo
    let err = false

    v["apellido"] === "" ? (apellido = "uk-form-danger") : ""
    v["cedula"] === "" ? (cedula = "uk-form-danger") : ""
    v["celular"] === "" ? (celular = "uk-form-danger") : ""
    v["email"] === "" ? (email = "uk-form-danger") : ""
    v["modelo"] == "" ? (modelo = "uk-form-danger") : ""
    v["name"] === "" ? (name = "uk-form-danger") : ""
    v["version_modelo"] == "Versión del vehículo"
      ? (version_modelo = "uk-form-danger")
      : ""

    Object.entries(v).forEach(element => {
      if (element[1] === "" || element[1] === "Versión del vehículo") {
        err = true
        this.setState({
          btnText: "ENVIAR",
          alert: "error",
          error: true,
          feedBackInput: {
            apellido: apellido,
            cedula: cedula,
            celular: celular,
            email: email,
            modelo: modelo,
            name: name,
            version_modelo: version_modelo,
          },
        })
      }
    })

    if (!err) {
      const datetime = Date.now();
      const raw = JSON.stringify({
        "flexformdata":
        {
          "FormCode": [[{ "value": "NWNhODhmYzMzNDg4Zg==" },
          { "datetime": datetime }, { "action": 1 }]], "name": [[{ "value": v["name"] },
          { "datetime": datetime }, { "action": 1 }]], "lastname": [[{ "value": v["apellido"] },
          { "datetime": datetime }, { "action": 1 }]], "email": [[{ "value": v["email"] },
          { "datetime": datetime }, { "action": 1 }]], "phone": [[{ "value": v["celular"] },
            { "datetime": datetime }, { "action": 1 }]], "modelo": [[{ "value": v["modelo"] },
            { "datetime": datetime }, { "action": 1 }]], "version_modelo": [[{ "value": v["version_modelo"] },
          { "datetime": datetime }, { "action": 1 }]]
        }
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      const requestOptions = {
        method: 'POST',
        'mode': 'cors',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };



      fetch(
        this.state.urlOrginForm +
          `wp-json/OmdFlex_forms/v1/save_form/5ca88fc33488f`,
        requestOptions
      )
        .then(response => {
          // document.querySelector('#cotizarForm input[type=button]').removeAttribute('disabled');
          this.setState({
            alert: "Gracias, hemos recibido su solicitud.",
            alertType: "uk-alert-success",
            error: false,
            btnText:"ENVIAR",
            feedBackInput: {
              apellido: "",
              cedula: "",
              celular: "",
              email: "",
              modelo: "",
              name: "",
              version_modelo: "",
            },
          })
          setInterval(() => {
            window.location.reload()
          }, 1000);
        })
        .catch(fail => {
          // document.querySelector('#cotizarForm input[type=button]').removeAttribute('disabled');
          this.setState({
            btnText: "ENVIAR", alertType: "uk-alert-danger",
            alertType: "uk-alert-danger",
            alert: "No hemos podido enviar su mensaje, intente más tarde."})
          console.log(fail)
        })
    }
  }

  render() {
    return (
      <div>
        <StaticQuery
          query={graphql`
            query {
              chevrolet {
                chevrolets(first: 1000, where: { status: PUBLISH }) {
                  edges {
                    node {
                      id
                      slug
                      title
                      content
                      featuredImage {
                        id
                        sourceUrl
                      }
                      versiones {
                        edges {
                          node {
                            precio_version
                            sku_version
                            version_modelo
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            this.state.datautos = data.chevrolet.chevrolets.edges
            const dataAutos = data.chevrolet.chevrolets.edges
            // console.log("this state=>",this.state);
            return (
              <div className="uk-container-large uk-container uk-margin-large">
                <form
                  id="cotizarForm"
                  className="uk-form-stacked uk-margin-medium"
                >
                  <div>
                    <div >
                      <div className="uk-margin">
                        <img src={this.state.featuredImage} />
                        <h4 className="uk-margin-remove-botom">
                          {this.state.title.includes(this.state.currentVercion) ? `${this.state.title.split(' ', 1 )} ` :  `${this.state.title} `  }
                          <span className="uk-text-color-red">
                            {this.state.currentVercion.replace('_',' ')}
                          </span>
                        </h4>
                        <h5 className="uk-text-lead uk-margin-remove-top uk-padding-remove">
                          {this.state.currentPrice != "" &&
                            this.state.currentPrice > 0 &&
                            `US$${new Intl.NumberFormat("en-IN").format(
                              this.state.currentPrice
                            )}`}
                        </h5>
                      </div>
                      <div className="uk-flex
                                uk-grid uk-child-width-1-2@m uk-child-width-1-1@s uk-margin" data-uk-grid="true">
                      <div className="uk-margin">
                        <div className="uk-form-controls">
                          <div className="uk-inline-c">

                            <select
                              className={`uk-select ${
                                this.state.feedBackInput.modelo
                              }`}
                              id="form-modelo-select"
                              name="modelo"
                              onChange={e => this.vehiculoChange(e.target.value)}
                            >
                              <option value="">Selecciona Modelo*</option>
                              {dataAutos.map(item => (
                                <option key={item.node.id} value={item.node.slug}>
                                  {item.node.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div >

                        <div className="uk-form-controls">
                          <div className="uk-inline-c">
                            <select
                              className={`uk-select ${
                                this.state.feedBackInput.version_modelo
                              }`}
                              id="form-version_modelo"
                              name="version_modelo"
                              onChange={this.versionChange}
                              value={this.state.currentVercion.replace('_',' ')}
                            >
                            
                                {this.state.versiones.length > 0 &&
                                this.state.versiones.map((items, key) => (                            
                                  
                                  <option
                                    key={key}
                                    value={items.node.version_modelo }
                                  >
                                    {items.node.version_modelo}
                                   
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>



                    <div  className="uk-flex uk-grid uk-child-width-1-2@m uk-child-width-1-1@s" data-uk-grid>


                    <div>
                      <h4>Información Personal</h4>
                      <div>
                        <div className="">
                          <label
                            className="uk-form-label"
                            htmlFor="form-name-text"
                          >
                            Nombre*
                          </label>
                          <div className="uk-form-controls">
                            <input
                              name="name"
                              className={`uk-input ${
                                this.state.feedBackInput.name
                              }`}
                              id="form-name-text"
                              type="text"
                              placeholder="Juan PXXXXXXX"
                              onChange={e => this.inputChange(e.target.value)}
                            />
                          </div>
                        </div>
                          <div className="uk-margin-medium">

                            <label
                              className="uk-form-label"
                              htmlFor="form-apellido-select"
                            >
                              Apellido*
                          </label>
                            <div className="uk-form-controls">
                              <input
                                name="apellido"
                                className={`uk-input ${this.state.feedBackInput.apellido
                                  }`}
                                id="form-apellido-text"
                                type="text"
                                placeholder="Mella RamXXX"
                              />
                            </div>
                          </div>
                        <div className="uk-margin-medium">
                          <label
                            className="uk-form-label"
                            htmlFor="form-cedula-text"
                          >
                            Cédula*
                          </label>
                          <div className="uk-form-controls">
                            <InputMask
                              name="cedula"
                              mask="999-9999999-9"
                              className={`uk-input ${
                                this.state.feedBackInput.cedula
                              }`}
                              id="form-cedula-text"
                              type="text"
                              placeholder="402-XXXXXXX-X"
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="uk-margin-small-top">
                      <div className="uk-margin-medium-top">

                          <label
                            className="uk-form-label"
                            htmlFor="form-email-text"
                          >
                            Email*
                          </label>
                          <div className="uk-form-controls">
                            <input
                              name="email"
                              className={`uk-input ${this.state.feedBackInput.email
                                }`}
                              id="form-email-text"
                              type="email"
                              placeholder="correo@emXXX.com"
                            />
                          </div>
                      </div>
                      <div className="uk-margin-medium">
                        <label
                          className="uk-form-label"
                          htmlFor="form-celular-text"
                        >
                          Celular*
                        </label>
                        <div className="uk-form-controls">
                          <InputMask
                            name="celular"
                            mask="999-999-9999"
                            className={`uk-input ${
                              this.state.feedBackInput.celular
                            }`}
                            id="form-celular-text"
                            type="text"
                            placeholder="840-5XX-XXXX"
                          />
                        </div>
                      </div>
                      <div className="uk-margin">
                        <small>Los campos marcados * son obligatorios.</small>
                        {this.state.alert == true && (
                          <div
                            className="uk-alert-success uk-padding-small"
                            uk-alert="true"
                          >
                            <a className="uk-alert-close" uk-close="true" />
                            <p>Tu solicitud ha sido enviada</p>
                          </div>
                        )}
                        {this.state.alert == "error" && (
                          <div
                            className="uk-alert-danger uk-padding-small"
                            uk-alert="true"
                          >
                            <a className="uk-alert-close" uk-close="true" />
                            <p>
                              Todos los campos marcados con (*) son obligatorios.
                            </p>
                          </div>
                        )}
                          <div className={this.state.alertType} data-uk-alert>
                            <a className="uk-alert-close" uk-close></a>
                            <p>{this.state.alert}</p>
                          </div>
                        <div className="uk-form-controls">
                          <input
                            className="btn btn--primary uk-width-1-2@m"

                            type="button"
                            onClick={this.SendEntries}
                            value={this.state.btnText}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>



                  <hr />
                  <h6>
                  •Al proporcionar mi información de contacto, doy mi permiso para que Suzuki Motors República Dominicana y/o uno de sus concesionarios, me contacten con más información de productos y ofertas de Suzuki.
                  </h6>
                </form>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default Cotizar
