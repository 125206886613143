import React, { Component } from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
const styles = require('../../utils/mapStyles.json')


export class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { center: [], zoom: 12, markers: [], filters: [] }
  }
  //Marcador con millas a la redonda de tu ubicacion actual
  mapRender() {
    let parent = this
    navigator.geolocation.getCurrentPosition(function(position) {
      parent.setState(
        {
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          zoom: 18,
          styles: styles.map
        },


        function() {
          const { center, zoom, filters } = parent.state
          const GoogleApi = parent.props.google
          var map = new GoogleApi.maps.Map(
              document.getElementById("map").children[0],
              {
                zoom,
                center,
                styles
              }
            ),
            UserRequest = {
              location: center,
              radius: "2000",
              type: filters.type,
              keyword: filters.keyword,
            },
            Office = {
              placeId: "",
            }
          /*            CircleOptions = {
              strokeColor: "#00A44F",
              strokeOpacity: 0.1,
              strokeWeight: 2,
              fillColor: "#00A44F",
              fillOpacity: 0.075,
              map: map,
              center: center,
              radius: 3000
            };*/
          let branchOffices = []
          //let infowindow = new GoogleApi.maps.InfoWindow();
          //let Circle = new GoogleApi.maps.Circle(CircleOptions)
          //Service for the search
          let service = new GoogleApi.maps.places.PlacesService(map)
          service.nearbySearch(UserRequest, PlacesMarker)
          //
          function PlacesMarker(results, status) {
            if (status === GoogleApi.maps.places.PlacesServiceStatus.OK) {
              for (var i = 0; i < results.length; i++) {
                var place = results[i]
                var Generalmarker

                Office["placeId"] = place["place_id"]

                branchOffices.push([
                  place.name,
                  place.geometry.location,
                  place.website,
                  place.rating,
                ])

                Generalmarker = new GoogleApi.maps.Marker({
                  position: place["geometry"]["location"],
                  clickable: true,
                  map,
                  title: place["name"],
                })

                let infowindow = new GoogleApi.maps.InfoWindow()
                infowindow.setContent(place.name)
                GoogleApi.maps.event.addListener(
                  Generalmarker,
                  "click",
                  function() {
                    //infowindow.setContent(place.name);

                    infowindow.open(map, this)
                    // map.setCenter(place.geometry.location);
                  }
                )
              }
            }
          }
        }
      ) // fin
    })
  }
  componentDidMount() {
    this.mapRender()
  }

  componentDidUpdate() {
    const { Filters } = this.props
    if (Filters !== this.state.filters) {
      this.setState({ filters: Filters })
      this.mapRender()
      //console.log("Estado", this.state);
    }
  }
  render() {
    return (
      <div id="map">
        <Map
          google={this.props.google}
          initialCenter={this.props.center}
          zoom={14}

        >
        <Marker
           name={'Suzuki'}
           position={{lat: 18.48327611220555, lng: -69.94027338208906}}
           icon={{
              url: "../../pin2.svg"
            }}
           

           />
           
        </Map>
      </div>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyCGVrpWSLsdKl0pH8-7oq4GPvxlB0mxrzg",
})(MapContainer)

/*

 https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=1500&type=restaurant&keyword=cruise&key=AIzaSyCGVrpWSLsdKl0pH8-7oq4GPvxlB0mxrzg


 */
