import React from "react"

const MapFilters = ({ concesionarioList, onClick }) => (
  <div
    id="concesionarioList"
    className="uk-margin uk-flex uk-flex-column uk-height-max-large"
  >
  <ul className="uk-list">
    {concesionarioList.map((item, key) => (
    <li className="uk-margin" key={key}>
        <a  href="#"  onClick={(e)=> onClick(e,{ lat: parseFloat(item.lat), lng: parseFloat(item.long) })} title={item.nombre}>
          <div
              className="uk-grid-small uk-flex-middle uk-grid"
              uk-grid="true"
            >
            <div className="uk-width-1-1 uk-card uk-card-default uk-card-body uk-border-rounded">
              <div className="uk-width-auto">
                <img src={item.logo} width={50} />
                <h4 className="uk-text-primary uk-margin-remove">{item.nombre}</h4>
                <p><strong>Dirección: </strong><small>{item.direccion}</small></p>
                <p className="uk-margin-remove-top">
                { item.contacto ? <><strong>Teléfono: </strong><small>{item.contacto}</small> </>: "" }
                
                </p>
              </div>
              </div>
          </div>
        </a>
       
      
    </li>
        ))}
</ul>

  </div>
)

export default MapFilters
