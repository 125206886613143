import React from "react"
import { graphql } from "gatsby"

//import Layout from "../layouts";
import SEO from "../components/seo"
//import Maps from "../components/Maps";
import Concesionario from "../components/concesionario"
import Formulario from "../components/formulario"

import Contacto from "../components/formulario/contacto.jsx"
import Caculadora from "../components/calculators/vehiculo.jsx"
import CotizarPiezas from "../components/formulario/cotizar-piezas"
// import Blogs from "../components/blog"

class Page extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            content: this.props.data.chevrolet.page.content,
            page: this.props.data.chevrolet
        }
    }


    componentDidMount() {
        const element = document.querySelector('#icons-area select');
        if(element !== null){
            element.addEventListener('change',(event) => {
                console.log(element.value);
               // document.querySelector(element.value).scrollIntoView(true);
                window.scrollBy(0, -94);
        });
    }

    const n = document.querySelectorAll('[data-postid="true"]')

    n.forEach(e => {
      let uri = new URL(e.href)
      let query_string = uri.search
      let search_params = new URLSearchParams(query_string)
      search_params.append("id", this.props.data.chevrolet.page.slug)
      uri.search = search_params.toString()
      let new_url = uri.toString()
      e.setAttribute("href", new_url)
    })

  }

  render() {
    return (
      <React.Fragment>

        <SEO title={this.state.page.page.title} keywords={[this.state.page.page.title, `Suzuki`]} />
        <div dangerouslySetInnerHTML={{ __html: this.state.page.page.content }} />


        {/* {this.state.page.page.id === "cGFnZToxNjA1" && <Blogs />} */}
        {this.state.page.page.id === "cGFnZTozNzk=" && <Concesionario pageId={this.state.page.page} />}
        {this.state.page.page.id === "cGFnZTo2ODA=" && <Formulario pageId={this.state.page.page} />}
        {this.state.page.page.id === "cGFnZTo2NzY=" && <Formulario pageId={this.state.page.page} />}
        {this.state.page.page.id === "cGFnZTo2Nzg=" && <Formulario pageId={this.state.page.page} oferta_text={ this.props.data.chevrolet.ofertas_description } />}
        {this.state.page.page.id === "cGFnZTo2NTg=" && <Contacto pageId={this.state.page.page} />}
        {this.state.page.page.id === "cGFnZTo2NzQ=" && <Caculadora pageId={this.state.page.page} />}
        {this.state.page.page.id === "cGFnZTo0MzA1" && <CotizarPiezas pageId={this.state.page.page} />}



      </React.Fragment>
    )
  }
}

export default Page

export const query = graphql`
    query($pageId: ID!) {
        chevrolet {

            page(id: $pageId) {
                id
                slug
                title
                content
            }
ofertas_description
        }
    }`
