/* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import shadowncard from "../../assets/img/default.png"
// import InputMask from "react-input-mask"
import FormTestDrive from "./form-test-drive"
const config = require("../../../config/app");

class TestDrive extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requesId: false,
      alert: false,
      error: false,
      datautos: [],
      btnText: 'Enviar',
      feedBackInput: {
        apellido: "",
        cedula: "",
        celular: "",
        email: "",
        modelo: "",
        name: "",
        version_modelo: "",
      },

      urlOrginForm: config.url_flexible_form,
      featuredImage: shadowncard,
      title: "",
      sending: "",
      currentPrice: "",
      currentVersion: "",
      versiones: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
    }

    this.vehiculoChange = this.vehiculoChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.SendEntries = this.SendEntries.bind(this)
    this.validateInput = this.validateInput.bind(this)
    this.inputChange = this.inputChange.bind(this)
    //this.onChange       = this.onChange.bind(this);
  }

  componentDidMount() {
    let uri = new URL(window.location)
    let slug = uri.search.split("=")
    const autos = this.state.datautos
    const dataAuto = autos.filter(row => row.node.slug === slug[1])
    if (dataAuto.length === 1) {
      this.setState({
        requesId: dataAuto[0].node.title,
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVersion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
      document.querySelector('select[name="modelo"]').value =
        dataAuto[0].node.slug
    }
  }

  vehiculoChange(e) {
    if (e !== "") {
      const dataAuto = this.state.datautos.filter(row => row.node.slug === e)
      this.setState({
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVersion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
    } else {
      this.setState({
        featuredImage: shadowncard,
        title: "",
        currentPrice: "",
        currentVersion: "",
        versiones: [
          {
            node: {
              precio_verion: "",
              version_modelo: "Versión del vehículo",
            },
          },
        ],
      })
    }
  }

  versionChange(e) {
    let { value } = e.target
    console.log('hola', value)
    const data = this.state.versiones.filter(
      row => row.node.version_modelo === value
    )

    this.setState({
      currentPrice: data[0].node.precio_version,
      currentVersion: data[0].node.version_modelo,
    })
  }

  SendEntries() {
    if (typeof window !== "undefined") {
      const cotizarForm = Array.from(document.forms["testDriveForm"].elements)
      // document.querySelector('#testDriveForm input[type=button]').setAttribute("disabled","disabled");
      this.setState({btnText:"ENVIANDO..."})
      let data = { flexformdata: {} }

      cotizarForm.forEach(n =>
        n.type == "text" || n.type == "select-one" || n.type == "email"
          ? (data["flexformdata"][n.name] = n.value)
          : ""
      )
      this.validateInput(data["flexformdata"])
    }
  }

  inputChange(e) {
    console.log(e)
  }

  validateInput(v) {
    let apellido, cedula, celular, email, modelo, name, version_modelo
    let err = false

    v["apellido"] === "" ? (apellido = "uk-form-danger") : ""
    v["cedula"] === "" ? (cedula = "uk-form-danger") : ""
    v["celular"] === "" ? (celular = "uk-form-danger") : ""
    v["email"] === "" ? (email = "uk-form-danger") : ""
    v["modelo"] == "" ? (modelo = "uk-form-danger") : ""
    v["name"] === "" ? (name = "uk-form-danger") : ""
    v["version_modelo"] == "Versión del vehículo"
      ? (version_modelo = "uk-form-danger")
      : ""

    Object.entries(v).forEach(element => {
      if (element[1] === "" || element[1] === "Versión del vehículo") {
        err = true
        this.setState({
          btnText:"ENVIAR",
          alert: "error",
          error: true,
          feedBackInput: {
            apellido: apellido,
            cedula: cedula,
            celular: celular,
            email: email,
            modelo: modelo,
            name: name,
            version_modelo: version_modelo,
          },
        })
      }
    })

    if (!err) {
      const datetime = Date.now();
      const raw = JSON.stringify({
        "flexformdata":
        {
          "FormCode": [[{ "value": "NWNhOTI5ZDZjNjk0ZQ==" },
          { "datetime": datetime }, { "action": 1 }]], "name": [[{ "value": v["name"] },
          { "datetime": datetime }, { "action": 1 }]], "lastname": [[{ "value": v["apellido"] },
          { "datetime": datetime }, { "action": 1 }]], "email": [[{ "value": v["email"] },
          { "datetime": datetime }, { "action": 1 }]], "phone": [[{ "value": v["celular"] },
          { "datetime": datetime }, { "action": 1 }]], "modelo": [[{ "value": v["modelo"] },
          { "datetime": datetime }, { "action": 1 }]], "version_modelo": [[{ "value": v["version_modelo"] },
          { "datetime": datetime }, { "action": 1 }]]
        }
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      const requestOptions = {
        method: 'POST',
        'mode': 'cors',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(
        this.state.urlOrginForm +
          `wp-json/OmdFlex_forms/v1/save_form/5ca929d6c694e`,
        requestOptions
      )
        .then(response => {
          this.setState({
            alert: "Su mensaje ha sido enviado exitosamente.",
            alertType: "uk-alert-success",
            btnText:"ENVIAR",
            error: false,
            feedBackInput: {
              apellido: "",
              cedula: "",
              celular: "",
              email: "",
              modelo: "",
              name: "",
              version_modelo: "",
            },
            
          })
          setInterval(() => {
            window.location.reload()
          }, 1000);
          // document.querySelector('#testDriveForm input[type=button]').removeAttribute('disabled');
        })
        .catch(fail => {
          // console.log(fail)
          // document.querySelector('#testDriveForm input[type=button]').removeAttribute('disabled');
          this.setState({
            btnText: "ENVIAR", alertType: "uk-alert-danger",
            alertType: "uk-alert-danger",
            alert: "no hemos podido enviar su mensaje, intente más tarde"
          })
        })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            chevrolet {
              chevrolets(first: 1000, where: { status: PUBLISH }) {
                edges {
                  node {
                    id
                    slug
                    title
                    content
                    featuredImage {
                      id
                      sourceUrl
                    }
                    versiones {
                      edges {
                        node {
                          precio_version
                          sku_version
                          version_modelo
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          this.state.datautos = data.chevrolet.chevrolets.edges
          const dataAutos = data.chevrolet.chevrolets.edges

          return (
            <div className="uk-container-large uk-container uk-margin-large">
              <FormTestDrive SendEntries={this.SendEntries} alert={this.state.alert} alertType={this.state.alertType} celular={this.state.feedBackInput.celular} cedula={this.state.feedBackInput.cedula} email={this.state.feedBackInput.email} apellido={this.state.feedBackInput.apellido} name={this.state.feedBackInput.name} dataAutos={dataAutos} featuredImage={this.state.featuredImage} title={this.state.title} currentVersion={this.state.currentVersion} currentPrice={this.state.currentPrice} modelo={this.state.feedBackInput.modelo} version_modelo={this.state.feedBackInput.version_modelo} vehiculoChange={this.vehiculoChange} versionChange={this.versionChange} versiones={this.state.versiones}/>
            </div>
          )
        }}
      />
    )
  }
}

export default TestDrive
