import React from 'react'

const Marker = ({ icon, onClick,onKeyPress, lat, lng, marker, type }) => (
  <React.Fragment>
    { type === 'current' ?
      <div className="current"></div> :
      
      <div
        data-icon-marker-ref={marker}
        className="marker"
        role="button"
        aria-hidden="true"
        onClick={(e) => onClick(e,{lat:parseFloat(lat), lng:parseFloat(lng)}) }
        onKeyPress={(e) => onKeyPress(e,{lat:parseFloat(lat), lng:parseFloat(lng)}) }
        //style={ icon ? {width:'21px', height:'37px',  border:'1px solid red', backgroundImage: `url(/${icon ? icon.split('/')[icon.split('/').length - 1] : 'pin2.svg'})`} : null }
      >
        <img src={'../../pin2.svg'} width={21}/>
      </div>
    }
  </React.Fragment> 
)

export default Marker
