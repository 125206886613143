import React from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";

function FormContact({ name, celular, email, alertType, alert, SendEntries }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => SendEntries(data);
  const isNumeric = (x) => {
    return !/\D/.test(x);
  };

  return (
    <form
      id="contactoForm"
      className="uk-form-stacked uk-margin-medium"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="">
        <label className="uk-form-label" htmlFor="form-name-text">
          Nombre y Apellido*
        </label>
        <div className="uk-form-controls">
          <input
            {...register("name", { required: true })}
            name="name"
            className={`uk-input ${name}`}
            id="form-name-text"
            type="text"
            placeholder="Juan PXXXXXXX"
            defaultValue={name}
            // onChange={(e) => this.inputChange(e.target.value)}
          />
          {errors.name && (
            <p
              style={{
                fontSize: "12px",
                color: "red",
                margin: "3px 0px -22px 15px",
              }}
            >
              El Nombre y Apellido son requeridos.
            </p>
          )}
        </div>
      </div>
      <div className="uk-margin-medium">
        <label className="uk-form-label" htmlFor="form-celular-text">
          Celular*
        </label>
        <div className="uk-form-controls">
          <ReactInputMask
            {...register("celular", { required: true})}
            name="celular"
            mask="999-999-9999"
            className={`uk-input ${celular}`}
            id="form-celular-text"
            type="text"
            placeholder="840-5XX-XXXX"
          />
          {errors.celular && errors.celular.type === "required" ? (
            <p
              style={{
                fontSize: "12px",
                color: "red",
                margin: "3px 0px -22px 15px",
              }}
            >
              El celular es requerido.
            </p>
          ) : (
            ""
          )}        
        </div>
      </div>
      <div className="uk-margin-medium">
        <label className="uk-form-label" htmlFor="form-email-text">
          Email*
        </label>
        <div className="uk-form-controls">
          <input
            {...register("email", { required: true })}
            name="email"
            className={`uk-input ${email}`}
            // defaultValue={this.state.feedBackInput.email}
            id="form-email-text"
            type="email"
            placeholder="correo@emXXX.com"
            // onChange={(e) => this.inputChange(e.target.value)}
          />
          {errors.email && (
            <p
              style={{
                fontSize: "12px",
                color: "red",
                margin: "3px 0px -22px 15px",
              }}
            >
              El Email es requerido.
            </p>
          )}
        </div>
      </div>
      <div className="uk-margin-medium">
        <label className="uk-form-label" htmlFor="form-email-text">
          Mensaje*
        </label>
        <div className="uk-form-controls">
          <textarea
            {...register("mensaje", { required: true })}
            style={{ resize: "no-resize" }}
            name="mensaje"
            className="uk-textarea"
            // defaultValue={this.state.feedBackInput.mensaje}
            // onChange={(e) => this.inputChange(e.target.value)}
          />
          {errors.mensaje && (
            <p
              style={{
                fontSize: "12px",
                color: "red",
                margin: "3px 0px -22px 15px",
              }}
            >
              El mensaje es requerido.
            </p>
          )}
        </div>
      </div>
      <div className={alertType} data-uk-alert>
        <a className="uk-alert-close" data-uk-close />
        <p>{alert}</p>
      </div>
      <div className="uk-form-controls">
        <button className="btn btn--primary uk-width-1-2@m">ENVIAR</button>
      </div>
    </form>
  );
}

export default FormContact;
