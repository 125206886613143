/* eslint-disable */
import React from "react";
const config = require("../../../config/app");

import { StaticQuery, graphql, Link } from "gatsby";
import shadowncard from "../../assets/img/default.png";

import TestDrive from './formulario-test-drive'
import Cotizar from './formulario-cotizar'
import Ofertas from './formulario-oferta'
class Formulario extends React.Component {
  constructor(props) {
    super(props)
  }




	render(){


/*

cGFnZTo2ODA=
test drive

cGFnZTo2Nzg=
ofertas

cGFnZTo2NzY=
cotizar

 */

if(this.props.pageId.id == "cGFnZTo2ODA=" ){
      return <TestDrive urlOrginForm={config.uff} />
}

if(this.props.pageId.id == "cGFnZTo2NzY=" ){
      return <Cotizar urlOrginForm={config.uff} />
}
if(this.props.pageId.id == "cGFnZTo2Nzg=" ){
      return <Ofertas urlOrginForm={config.uff} oferta_text={ this.props.oferta_text } />
}

    return ''

	}


}

export default Formulario;

