const config = require("../../../config/app")

const isBrowser = () => typeof window !== "undefined";

export async function fetchAPI(query, { variables } = {}) {
  const headers = { "Content-Type": "application/json" };
  // const API_URL = config.uff + '/index.php?graphql';

  // TODO Corregir url Hardcoding, se le puso un proxy al NGINX del server y este cambia.
  //const API_URL = 'https://suzuki.back.omddominicana1.com/index.php?graphql';
    const API_URL = 'https://back.suzuki.com.do/graphql';
  //console.log('URL->',API_URL);
    //const API_URL = isBrowser() ? "/api/gql" : process.env.API_URL;
  // const API_URL = isBrowser() ? "https://bscv2.back.omddominicana1.com/index.php?graphql" : process.env.API_URL;

  const res = await fetch(API_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({ query, variables }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    // throw new Error('Failed to fetch API')
  }

  return json.data;
}
