import React from "react"
import MapaConcesionarios from './mapConcesionarios'


class Concesionario extends React.Component {

    render(){
        return(
            <>
                <MapaConcesionarios/>
            </>
        )
    }

}

export default Concesionario