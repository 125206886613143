/* eslint-disable */
import React from "react"
import { connect } from 'react-redux';
import { StaticQuery, graphql, Link } from "gatsby"
import shadowncard from "../../assets/img/default.png"
import InputMask from "react-input-mask"
import { formActions } from '../../redux/_actions'

class Ofertas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      marca: {
        value: 1,
        label: "Chevrolet",
      },
       resultChassis : "",
      chasis: "",
    }
    this.chasisChange = this.chasisChange.bind(this)
  }

  chasisChange(e) {
    this.setState({
      chasis: e,
    })
  }


  submitCampaign(e){
    e.preventDefault()
    this.setState({ submitted: true, resultChassis : "Buscando...", })
      const { chasis } = this.state
      const { dispatch } = this.props
    if (chasis) {
        dispatch(formActions.chassis(chasis))
        this.setState({
          submitted: false,
          alert: {},
          loading: true
        })
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if(nextProps.chassis) {
      this.setState({loading: false, resultChassis: nextProps.chassis});
    }
  }


  render() {
      const {  chasis,  resultChassis } = this.state;
    return (
      <div className="uk-container uk-margin-large-top">
        <div className="uk-padding" dangerouslySetInnerHTML={{ __html : this.props.oferta_text }}></div>
        <div
          className="uk-child-width-1-2@m uk-background-muted uk-padding uk-margin-large-top"
          data-uk-grid
        >
          <div>
            <h2>CAMPAÑAS DE SERVICIOS</h2>
            <div>
              <form onSubmit={this.submitCampaign.bind(this)} >
                <legend className="uk-legend uk-flex">
                  <span className="uk-margin-right">Ingrese número de chasis</span>
                  <hr className="uk-flex-1" />
                </legend>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="form-stacked-text">
                    Chasis
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="form-stacked-text"
                      type="num"
                      onChange={e => this.chasisChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="uk-margin uk-flex uk-flex-around">
                  <button className="uk-button uk-button-default uk-boder-yelow">
                    Buscar
                  </button>

                </div>
              </form>
            </div>
          </div>
          <div>
            <div className="panel-campaign">
              <div className="uk-padding">
                <ul className="uk-list">

                  <li>
                    Chasis: <strong>{this.state.chasis} </strong>
                  </li>
                  <li> Resultado:</li>
                </ul>
                <div className="uk-placeholder">
                  <span dangerouslySetInnerHTML={{__html: resultChassis}}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { alert, chassis } = state

  return {
    alert,
    chassis
  };
}

export default connect(mapStateToProps)(Ofertas)
