import React, { Component, useEffect } from 'react';
import MapFilters from '../map/mapFilters.jsx'
import GoogleMapReact from 'google-map-react';
import { useMapStore } from '../../lib/stores';
import { isEmpty } from 'lodash/fp';
import Marker from './marker';
const styles = require('../../utils/mapStyles.json')



export default () => {

  const createMapOptions = (maps) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: styles.map
    }
  }

  const mapStore = useMapStore();

  useEffect(()=>{
    mapStore.loadMarkers();
  },[])

  const { 
    markers,
    searchResults, 
    map, 
    updateZoom, 
    updateCenter
  } = useMapStore();
  
  const getDirections = (e,data)=>{
    e.preventDefault();
    // console.log('DATA->',data);
    // let currentMarkerItem = document.querySelector('.uk-description-list.marker-active');
    // if ( currentMarkerItem != null && currentMarkerItem != undefined ) currentMarkerItem.classList.remove('marker-active');
    // let markerItem = document.getElementById(`${e.target.dataset.iconMarkerRef}`);
    // let markerContent = window.innerWidth > 959 ? ".filter-content" : ".bsc-map";
    // let smoothScroll = require("../../utils").smoothScroll;
    // smoothScroll(
    //   document.querySelector(markerContent),
    //   markerItem,
    //   600,
    //   100
    // );
    // markerItem.classList.add('marker-active');
    // let currentActive = Array.from( document.getElementsByClassName('marker active') );
    // if( currentActive.length > 0) currentActive[0].classList.remove('active');
    // e.target.classList.add('active')
    updateZoom(15)
    updateCenter(data)
  }


  return (
    <div className="uk-container uk-container-large uk-padding-large">
    <div className="uk-grid uk-grid-match uk-flex" uk-grid="true">

        <div id="MapFilter" className="uk-width-1-3@m uk-width-1-1@s">
          <form id="MapFormfilter" className="uk-margin-large">
            <MapFilters concesionarioList={searchResults} onClick={(e,data)=>{ getDirections(e,data)}} />
          </form>
        </div>
        <div className="uk-width-expand@m uk-width-1-1@s uk-height-1-1">
          <div id="map">
   
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCGVrpWSLsdKl0pH8-7oq4GPvxlB0mxrzg" }}
                    defaultCenter={{ lat: 18.65657, lng: -72.3743431}}
                    defaultZoom={20}
                    center={map.center}
                    zoom={map.zoom}
                    options={createMapOptions}
                    onChange={ (e)=> {
                        updateZoom(e.zoom)
                      }
                    }
                  >
                  {
                    !isEmpty(searchResults) ? 
                    searchResults.map( (marker,i)=>{
                      return (
                        <Marker
                          key={marker.id}
                          lat={marker.lat}
                          lng={marker.long}
                          icon={
                            marker.type[0] === "branchOffice"
                            ? "pin2.svg"
                              : marker.type[0] === "ath"
                              ? "pin2.svg"
                              : marker.type[0] === "autoBank"
                              ? "pin2.svg"
                              : marker.type[0] === "subAgents"
                              ? "pin2.svg"
                              : null
                          }
                          marker={marker.id}
                          type={"branchOffice"}
                          onClick={ (e,data) => getDirections(e,data) }
                          onKeyPress={ (e,data) => getDirections(e,data) }
                        /> 
                        
                      )
                    })
                    : ""
                  }
                    
                  </GoogleMapReact>
          
          </div>
        </div>

    </div>
</div>
      
    );
}

