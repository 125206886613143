import React from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";

function FormTestDrive({
  alertType,
  alert,
  celular,
  cedula,
  email,
  name,
  apellido,
  featuredImage,
  title,
  currentVersion,
  currentPrice,
  modelo,
  vehiculoChange,
  versionChange,
  dataAutos,
  version_modelo,
  versiones,
  SendEntries,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => SendEntries(data);
  const isNumeric = (x) => {
    return !/\D/.test(x);
  };

  return (
    <form
      id="testDriveForm"
      className="uk-form-stacked uk-margin-medium"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        {/* <h4>1. Tu Vehículo</h4> */}

        <div className="uk-margin">
          <img src={featuredImage} />
          <h4 className="uk-margin-remove-botom">
            {title} <span className="uk-text-color-red">{currentVersion}</span>
          </h4>
          <h5 className="uk-text-lead uk-margin-remove-top uk-padding-remove">
            {currentPrice != "" &&
              currentPrice > 0 &&
              `$${new Intl.NumberFormat("en-IN").format(currentPrice)}`}
          </h5>
        </div>

        <div
          className="uk-flex uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
          data-uk-grid
        >
          <div className="uk-margin">
            <div className="uk-form-controls">
              <div className="uk-inline-c">
                {/* <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          /> */}
                <select
                  {...register("modelo", { required: true })}
                  className={`uk-select ${modelo}`}
                  id="form-modelo-select"
                  name="modelo"
                  onChange={(e) => vehiculoChange(e.target.value)}
                >
                  <option value="">Selecciona Modelo*</option>
                  {dataAutos.map((item) => (
                    <option key={item.node.id} value={item.node.slug}>
                      {item.node.title}
                    </option>
                  ))}
                </select>
                {errors.modelo && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Modelo es requerido.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="uk-form-controls">
              <div className="uk-inline-c">
                {/* <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          /> */}
                <select
                  className={`uk-select ${version_modelo}`}
                  id="form-version_modelo"
                  name="version_modelo"
                  onChange={versionChange}
                >
                  {versiones.length > 0 &&
                    versiones.map((items, key) => (
                      <option key={key} value={items.node.version_modelo}>
                        {items.node.version_modelo}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          className="uk-flex uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
          data-uk-grid
        >
          <div>
            <h4>Información Personal</h4>
            <div>
              <div className="">
                <label className="uk-form-label" htmlFor="form-name-text">
                  Nombre*
                </label>
                <div className="uk-form-controls">
                  <input
                    {...register("name", { required: true })}
                    name="name"
                    className={`uk-input ${name}`}
                    id="form-name-text"
                    type="text"
                    placeholder="Juan PXXXXXXX"
                    // onChange={e => this.inputChange(e.target.value)}
                  />
                  {errors.name && (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "3px 0px -22px 15px",
                      }}
                    >
                      El Nombre es requerido.
                    </p>
                  )}
                </div>
              </div>
              <div className="uk-margin-medium">
                <label className="uk-form-label" htmlFor="form-apellido-select">
                  Apellido*
                </label>
                <div className="uk-form-controls">
                  <input
                    {...register("apellido", { required: true })}
                    name="apellido"
                    className={`uk-input ${apellido}`}
                    id="form-apellido-text"
                    type="text"
                    placeholder="Mella RamXXX"
                  />
                  {errors.apellido && (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "3px 0px -22px 15px",
                      }}
                    >
                      El apellido es requerido.
                    </p>
                  )}
                </div>
              </div>
              <div className="uk-margin-medium">
                <label className="uk-form-label" htmlFor="form-cedula-text">
                  Cédula*
                </label>
                <div className="uk-form-controls">
                  {/* <InputMask
                    name="cedula"
                    mask="999-9999999-9"
                    className={`uk-input ${cedula}`}
                    id="form-cedula-text"
                    type="text"
                    placeholder="402-XXXXXXX-X"
                  /> */}
                  <InputMask
                    {...register("cedula", {
                      required: true
                    })}
                    mask="999-9999999-9"
                    name="cedula"
                    className={`uk-input ${cedula}`}
                    id="form-cedula-text"
                    type="text"
                    // maxLength='11'
                    placeholder="Cédula"
                  />
                  {errors.cedula && errors.cedula.type === "required" ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "3px 0px -22px 15px",
                      }}
                    >
                      La Cédula es requerido.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="uk-margin-small-top">
            <div className="uk-margin-medium-top">
              <label className="uk-form-label" htmlFor="form-email-text">
                Email*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("email", { required: true })}
                  name="email"
                  className={`uk-input ${email}`}
                  id="form-email-text"
                  type="email"
                  placeholder="correo@emXXX.com"
                />
                   {errors.email && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Email es requerido.
                  </p>
                )}
              </div>
            </div>

            <div className="uk-margin-medium">
              <label className="uk-form-label" htmlFor="form-celular-text">
                Celular*
              </label>
              <div className="uk-form-controls">
                {/* <InputMask
                  name="celular"
                  mask="999-999-9999"
                  className={`uk-input ${celular}`}
                  id="form-celular-text"
                  type="text"
                  placeholder="840-5XX-XXXX"
                /> */}
                <InputMask
                {...register("celular", { required: true })}
                  name="celular"
                  mask="999-999-9999"
                  className={`uk-input ${celular}`}
                  id="form-celular-text"
                  type="text"
                  placeholder="840-5XX-XXXX"
                  // maxLength='10'
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.celular && (errors.celular.type === "required") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>El celular es requerido.</p>) : ''}

              </div>
            </div>
            <div className="uk-margin">
              <small>Los campos marcados * son obligatorios.</small>
              {alert == true && (
                <div
                  className="uk-alert-success uk-padding-small"
                  uk-alert="true"
                >
                  <a className="uk-alert-close" uk-close="true" />
                  <p>Tu solicitud ha sido enviada</p>
                </div>
              )}

              {alert == "error" && (
                <div
                  className="uk-alert-danger uk-padding-small"
                  uk-alert="true"
                >
                  <a className="uk-alert-close" uk-close="true" />
                  <p>Todos los campos marcados con (*) son obligatorios.</p>
                </div>
              )}
              <div className={alertType} data-uk-alert>
                <a className="uk-alert-close" uk-close />
                <p>{alert}</p>
              </div>

              <div className="uk-form-controls">
                {/* <input
                          className="btn btn--primary uk-width-1-2@m"
                          id="form-stacked-text"
                          type="button"
                          onClick={this.SendEntries}
                          value={this.state.btnText}
                        /> */}
                <button className="btn btn--primary uk-width-1-2@m">
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h6>
        •Al hacer click en enviar usted nos autoriza hacer uso de la información
        compartida con las entidades financieras para gestionar a su favor el
        préstamo solicitado.
      </h6>
    </form>
  );
}

export default FormTestDrive;
