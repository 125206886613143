import { fetchAPI } from "../api/fetchAPI";

export async function getMarkers() {

const query = `
query  {
  page(id:"cGFnZTozNzk="){
  id
      title
      concesionarios (first:100) {
        edges {
          node {
            como_llegar
            contacto
            descripcion
            direccion
            lat
            logo
            long
            nombre

            }
          }
        }
}
  }
`;
  const data = await fetchAPI(query, {
    variables: {},
  });

  return { ...data }
}




