/* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import SimpleMap from "../Maps/Map.js"
const config = require("../../../config/app");
import "./fornt.css"
import FormContact from "./form-contact.jsx";

class Contacto extends React.Component {
  constructor(props) {
    super(props)

    // this.inputChange = this.inputChange.bind(this);
    this.SendEntries = this.SendEntries.bind(this);
    this.validateInput = this.validateInput.bind(this);

    this.state = {
      status: "init",
      alert: "",
      alertType: "uk-hidden",
      error: false,
      btnText: "ENVIAR",
      urlOrginForm: config.url_flexible_form,
      feedBackInput: {
        name: "",
        phone: "",
        email: "",
        mensaje: "",
      },
      feedBackAlert: {
        name: "",
        phone: "",
        email: "",
        mensaje: "",
      },
      filters: {
        type: "chevrolet dealer",
        keyword: "Santo Domingo Motors Chevrolet",
      },
    }

   

  }


  SendEntries(dataFromContact) {
    // console.log('ESTOY ENTRANDO')

    if (typeof window !== "undefined") {
      
      const contactForm = Array.from(document.forms["contactoForm"].elements)
      // this.setState({btnText:"ENVIANDO..."})
      let data = { flexformdata: {} }

      contactForm.forEach(n =>
        n.type == "text" || n.type == "select-one" || n.type == "email"
          ? (data["flexformdata"][n.name] = n.value) : ""
      )

      //document.querySelector('#contactoForm input[type=button]').setAttribute("disabled","disabled");

      this.validateInput(dataFromContact)

    }
  }

  validateInput(v) {
    let mensaje, celular, email,name
    let err = false

    v["celular"]  === "" ? (celular = "uk-form-danger") : ""
    v["email"]    === "" ? (email = "uk-form-danger") : ""
    v["name"]     === "" ? (name = "uk-form-danger") : ""
    v["mensaje"]  === "" ? (mensaje = "uk-form-danger") : ""

    Object.entries(v).forEach(element => {
      if (element[1] === "") {
        err = true
        this.setState({
          alertType: "uk-alert-danger",
          alert: "no hemos podido enviar su mensaje, intente más tarde",
          error: true,
          feedBackAlert:{
            celular: celular,
            email: email,
            mensaje: mensaje,
            name: name,
          }
        })
      }
    })



    if (!err) {
      const datetime = Date.now();

      const raw = JSON.stringify({
        "flexformdata":
        {
          "FormCode": [[{ "value": "NWNhNzM3N2YzZTc4MA==" },
            { "datetime": datetime }, { "action": 1 }]], "name": [[{ "value": v["name"] },
            { "datetime": datetime }, { "action": 1 }]], "email": [[{ "value": v["email"] },
            { "datetime": datetime }, { "action": 1 }]], "phone": [[{ "value": v["celular"] },
            { "datetime": datetime }, { "action": 1 }]], "message": [[{ "value": v["mensaje"] },
            { "datetime": datetime }, { "action": 1 }]]
        }
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      const requestOptions = {
        method: 'POST',
        'mode': 'cors',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(
        `${this.state.urlOrginForm}wp-json/OmdFlex_forms/v1/save_form/5ca7377f3e780`,
        requestOptions
      )
      .then(response => {

          //document.querySelector('#contactoForm input[type=button]').removeAttribute('disabled');
          this.setState({
            alert: "Su mensaje ha sido enviado exitosamente.",
            alertType:"uk-alert-success",
            error: false,
            btnText:"ENVIAR",
            feedBackInput: {
              celular: "",
              email: "",
              mensaje:"",
              name: "",
            },
            feedBackAlert: {
              celular: "",
              email: "",
              mensaje:"",
              name: "",
            },
          })
          setInterval(() => {
            window.location.reload()
         }, 1000);
        })
        .catch(fail => {
  //        document.querySelector('#contactoForm input[type=button]').removeAttribute('disabled');

          this.setState({ btnText: "ENVIAR",
                          error: true,
                          alertType: "uk-alert-danger",
                          alert:"no hemos podido enviar su mensaje, intente más tarde"
                        })

          console.log(fail)
        })
    }else{
      //document.querySelector('#contactoForm input[type=button]').removeAttribute('disabled');
      this.setState({btnText:"ENVIAR"})
    }
  }
  inputChange(e) {
   // console.log(e)
  }
  render() {
    if (this.props.pageId.id == "cGFnZTo2NTg=") {
      return (
        <div className="uk-container uk-margin-large">
          <div className="uk-width-1-1 uk-grid">
            <div className="uk-width-1-2@m uk-width-1-1@s">
              <div className="uk-width-1-1">
                <div id="contacto-form">
               <FormContact name={this.state.feedBackAlert.name} celular={this.state.feedBackAlert.celular} email={this.state.feedBackAlert.email} alertType={this.state.alertType} alert ={this.state.alert} SendEntries={this.SendEntries}/>
                </div>

                <div id="contactos-map" />
              </div>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1@s">
              <SimpleMap
                google={this.props.google}
                center={{
                  lat: 18.48327611220555,
                  lng: -69.94027338208906
                }}
                
                // zoom={30}
                Filters={this.state.filters}
              >
              </SimpleMap>
            </div>
          </div>
        </div>
      )
    }
    return ""
  }
}

export default Contacto
